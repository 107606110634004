
import React from "react"
import { StaticImage } from 'gatsby-plugin-image'

import './index.scss';

const TestPage: React.FC = () => {
    const apiURL = process.env.API_URL
    console.log(apiURL,'asdasdasdasdadadasdasdasdas')
    return (
        <div id="test">
            StaticImage
            <div style={{ background: '#ccc' }}>
                <StaticImage src={'../../assets/images/index/cw_logo.png'} alt={""} />
            </div>

            <hr />
            img
            <div style={{ background: '#ccc' }}>
                {/* <img src={logo} /> */}
            </div>
        </div>
    )
}


export default TestPage;
